export const Quotes = [
  {
    id: 1,
    quote:
      'You are one of my most favourite people to work with. Your attitude is second to none, you are positive and a joy to work with, finding solutions, amazing at collaboration and producing excellent quality work. To give you a sense of how I much value and appreciate you, I want you to know that if I were to start a company, you would be one of the first 5-10 people to call to join me.',
    name: 'George',
    company: 'ZOE Co-Founder',
  },
  {
    id: 2,
    quote:
      "Hannah is the most talented developer we have ever worked with. With her vast skill set she was able to execute any level of complexity that we requested within the design. Hannah's attention to detail when creating the site was truly amazing, there was no back and forth with alignment or spacings, she creates everything exactly to plan with very little if any changes ever needed.",
    name: 'Scarlett',
    company: 'Freerider Co-Founder',
  },
  {
    id: 3,
    quote:
      "How great is Hannah..? 100% great. Organised, reliable and so talented at all the techy stuff that I just don't get (so patience is another one to add to the list). Working in design, I have very clear ideas on what I want; Hannah was able to translate these ideas into a highly-functional and good-looking site. She is super-responsive; even now the site is up and running, she still gets straight on any queries or additions. She has been a true partner in the design, development and build of my new site.",
    name: 'Sharon',
    company: 'Made In Pink Founder',
  },
  {
    id: 4,
    quote:
      "Hannah has been absolutely amazing for us. We wanted develop our business, widening our audience by selling our goods online. Our lack of knowledge in this area meant we really needed to find someone with real integrity, someone who would guide us through the process, building us a site that worked from the start. Never phased by our questions Hannah always listened, understood and developed our ideas. The result is a smart looking, intuitive and easy to use ecommerce site that we couldn't be more happy with. We honestly can not recommend Hannah highly enough.",
    name: 'Leo',
    company: "L'Affinage du Fromage",
  },
  {
    id: 5,
    quote:
      "As a developer, Hannah has proven herself to be extremely capable, not only at coding in React , CSS, and PHP, but also with implementing 'best practice' solutions, such as the versatile Syntactically Awesome Style Sheets (SASS), and compiled/minified JS, which is great for page load times. As an agency with a strong SEO focus, we love the fact that Hannah is completely at easy with the concept of structured data, happily implementing schema using JSON-LD. I'd be happy to recommend Hannah and look forward to working with her soon on many more projects.",
    name: 'Mike',
    company: 'Thinkable Agency',
  },
  {
    id: 6,
    quote:
      'Hannah is fantastic! It is rare to find such technical skills along with great taste and design abilities. As part of the job we asked Hannah to deal with a technical component she was not familiar with. She managed to overcome many obstacles quickly without the help of the component’s technical support. Hannah showed an impressive work ethic. She was very responsive, showed responsibility beyond her scope, and in general she was a pleasure to work with. From now on, she is our go to person with any matter regarding our website.',
    name: 'Yoel De Pinho Sarni',
    company: 'Owner at COUI Skincare',
  },
];
