/* eslint-disable func-names */
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Image from 'next/image';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledCarousel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0 0;
  overflow: hidden;
  max-height: 800px;
`;

const StyledColumn = styled.div``;

const StyledCard = styled.div`
  will-change: transform;
  height: 0;
  overflow: hidden;
  width: 52vw;
  padding-top: 228%;
  position: relative;
`;

export const CarouselMobile = () => {
  gsap.registerPlugin(ScrollTrigger);
  const carouselRef = useRef(null);
  const columnOneRef = useRef(null);
  const columnTwoRef = useRef(null);

  useEffect(() => {
    const columnOne = columnOneRef.current;
    const columnTwo = columnTwoRef.current;
    const carousel = carouselRef.current;

    gsap.to(columnOne, {
      scrollTrigger: {
        trigger: carousel,
        end: 'bottom top',
        scrub: true,
        start: 'top top',
      },
      y: 200,
    });

    gsap.to(columnTwo, {
      scrollTrigger: {
        trigger: carousel,
        end: 'bottom top',
        scrub: true,
        start: 'top top',
      },
      y: -180,
    });
  }, [columnTwoRef, columnOneRef]);

  return (
    <StyledCarousel ref={carouselRef}>
      <StyledColumn ref={columnOneRef}>
        <StyledCard>
          <Image
            src="/images/work/eon-next.png"
            layout="fill"
            sizes="50vw"
            alt="EON Next"
            quality={85}
            priority
          />
        </StyledCard>
        <StyledCard>
          <Image
            src="/images/work/zoe-nutrition.png"
            layout="fill"
            sizes="50vw"
            alt="ZOE - Nutrition"
            quality={85}
            priority
          />
        </StyledCard>
        <StyledCard>
          <Image
            src="/images/work/rita.png"
            layout="fill"
            sizes="50vw"
            alt="Rita - Take charge of your reproductive health"
            quality={85}
            priority
          />
        </StyledCard>
        <StyledCard>
          <Image
            src="/images/work/freerider.png"
            layout="fill"
            sizes="50vw"
            alt="Award-winning baby carriers"
            quality={85}
            priority
          />
        </StyledCard>
        <StyledCard>
          <Image
            src="/images/work/sister-co.png"
            layout="fill"
            sizes="50vw"
            alt="Sister &amp; Co - Everyday luxuries that don't cost the earth"
            quality={85}
            priority
          />
        </StyledCard>
      </StyledColumn>
      <StyledColumn ref={columnTwoRef}>
        <StyledCard>
          <Image
            src="/images/work/zoe-covid-symptom-study.png"
            layout="fill"
            sizes="50vw"
            alt="ZOE - COVID Symptom Study"
            quality={85}
            priority
          />
        </StyledCard>
        <StyledCard>
          <Image
            src="/images/work/laffinage.png"
            layout="fill"
            sizes="50vw"
            alt="L'Affinage - Cheese, wine charcuterie &amp; more"
            quality={85}
            priority
          />
        </StyledCard>
        <StyledCard>
          <Image
            src="/images/work/regen.png"
            layout="fill"
            sizes="50vw"
            alt="Regen - Cleaning up the Cayman Islands"
            quality={85}
            priority
          />
        </StyledCard>
        <StyledCard>
          <Image
            src="/images/work/hub8.png"
            layout="fill"
            sizes="50vw"
            alt="Hub8"
            quality={85}
            priority
          />
        </StyledCard>
      </StyledColumn>
    </StyledCarousel>
  );
};
