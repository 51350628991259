/* eslint-disable func-names */
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useEffect, useRef, useState } from 'react';

import { Container } from '@/components/Shared';
import { getLightHouseScore } from '@/lib/utils/getLightHouseScore';
import { isValidUrl } from '@/lib/utils/isValidUrl';

import { Lighthouse } from './Lighthouse';
import {
  AccessibilityMetrics,
  PerformanceMetrics,
  SEOMetrics,
} from './metrics';
import {
  StyledCards,
  StyledErrorMsg,
  StyledForm,
  StyledIntro,
  StyledPerformance,
  StyledPreTitle,
} from './Performance.styles';
import PerformanceCard from './PerformanceCard';

export const Performance = () => {
  const [loading, setLoading] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [websiteURLisValid, setWebsiteURLisValid] = useState(false);
  const [performanceMetrics, setPerformanceMetrics] =
    useState(PerformanceMetrics);
  const [performanceScore, setPerformanceScore] = useState(99);

  const handleURLChange = (event) => {
    setWebsiteURL(event.target.value);
    if (isValidUrl(event.target.value)) {
      setWebsiteURLisValid(true);
    }
  };

  const handleSubmit = async () => {
    getLightHouseScore(
      websiteURL,
      setLoading,
      setPerformanceScore,
      setPerformanceMetrics,
      websiteURLisValid,
      setFormSubmitted
    );
  };

  gsap.registerPlugin(ScrollTrigger);
  const performanceRef = useRef(null);
  const accessibilityRef = useRef(null);
  const SEORef = useRef(null);

  useEffect(() => {
    const performanceDiv = performanceRef.current;
    const accessibility = accessibilityRef.current;
    const seoDiv = SEORef.current;

    gsap.to(accessibility, {
      scrollTrigger: {
        scrub: 2,
        start: 'top bottom',
        end: 'bottom bottom',
        trigger: performanceDiv,
      },
      rotate: -14,
      top: 20,
      left: -10,
      scale: 0.9,
    });

    gsap.to(seoDiv, {
      scrollTrigger: {
        scrub: 2,
        start: 'top bottom',
        end: 'bottom bottom',
        trigger: performanceDiv,
      },
      rotate: 14,
      top: 20,
      left: 10,
      scale: 0.9,
    });
  }, [accessibilityRef, performanceRef, SEORef]);

  return (
    <StyledPerformance ref={performanceRef}>
      <Container>
        <StyledPreTitle>Core Web Vitals</StyledPreTitle>
        <h2>Always fast. Always accessible.</h2>
        <StyledIntro>
          How your website performs is essential in delivering a great user
          experience. A top performing website will also rank well against
          competitors in search engine results.
        </StyledIntro>
        <StyledIntro>
          <strong>Want see how your website scores?</strong>
        </StyledIntro>
        <StyledForm>
          <input
            type="text"
            id="url"
            name="url"
            placeholder="Enter your website URL"
            onChange={handleURLChange}
            value={websiteURL}
          />
          <button type="submit" value="Analyze" onClick={handleSubmit}>
            Analyse
          </button>
        </StyledForm>

        {formSubmitted && !websiteURLisValid && (
          <StyledErrorMsg>Please enter a valid URL</StyledErrorMsg>
        )}
        <StyledCards>
          <PerformanceCard
            title="Accessibility"
            rating={100}
            showStats
            metrics={AccessibilityMetrics}
            position="left"
            ref={accessibilityRef}
            loading={loading}
          />
          <PerformanceCard
            title="Performance"
            rating={performanceScore}
            showStats
            metrics={performanceMetrics}
            position="center"
            loading={loading}
            websiteURL={websiteURL}
          />
          <PerformanceCard
            title="SEO"
            rating={100}
            showStats
            metrics={SEOMetrics}
            position="right"
            ref={SEORef}
            loading={loading}
          />
        </StyledCards>
        <Lighthouse />
      </Container>
    </StyledPerformance>
  );
};
