import styled from 'styled-components';

type TLoader = {
  loading: boolean;
};

const StyledLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transform: translateY(0);
  transition: transform 0.7s cubic-bezier(0.55, 0, 0.1, 1);
  will-change: transform;

  &.loaded {
    transform: translateY(-100%);
  }
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    line-height: 100%;
    margin: 0;
    font-weight: 800;
    text-align: left;
    letter-spacing: -0.08rem;
    font-size: 35px;
  }

  .words-wrap {
    height: 42px;
  }

  .words {
    overflow: hidden;
    height: 42px;

    h1 {
      display: block;
      height: 100%;
      animation: spin_words 2.5s forwards;
      padding: 5px 0;
    }
  }

  @keyframes spin_words {
    10% {
      transform: translateY(-112%);
    }
    25% {
      transform: translateY(-100%);
    }
    35% {
      transform: translateY(-212%);
    }
    50% {
      transform: translateY(-200%);
    }
    60% {
      transform: translateY(-312%);
    }
    75% {
      transform: translateY(-300%);
    }
    85% {
      transform: translateY(-412%);
    }
    100% {
      transform: translateY(-400%);
    }
  }
`;

export const Loader = ({ loading }: TLoader) => {
  return (
    <StyledLoader className={`${loading ? 'loading' : 'loaded'}`}>
      <StyledInner>
        <div className="words-wrap">
          <div className="words">
            <h1>Website</h1>
            <h1>Next.js</h1>
            <h1>React</h1>
            <h1>Marketing</h1>
            <h1>Hannah</h1>
          </div>
        </div>
        <h1>King</h1>
      </StyledInner>
    </StyledLoader>
  );
};
