import Image from 'next/image';
import styled from 'styled-components';

import { Button } from '@/components/Shared';
import { BREAKPOINTS } from '@/theme';
import type { TWorkItem } from '@/types/Work.types';

type TWork = {
  work: TWorkItem;
};

const StyledCard = styled.div`
  background-color: white;
  display: block;
  border-radius: 25px;
  margin: 20px 0 80px;
  box-shadow: rgba(21, 20, 26, 0.1) 0px 30px 60px -12px,
    rgba(21, 20, 26, 0.2) 0px 18px 36px -18px;
  text-align: left;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  cursor: grab;
  margin-top: 40px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
    margin-top: 20px;
  }
`;

const StyledText = styled.div`
  width: 50%;
  padding: 50px 40px 50px 60px;

  h2 {
    margin: 60px 0 0 0;
  }

  p {
    color: var(--text-grey);
    font-size: 16px;
    margin: 12px 0;
    font-weight: 500;
  }

  a {
    padding: 11px 35px;
    margin-top: 20px;
    display: inline-flex;

    span {
      margin-right: 8px;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    padding: 30px 20px;

    h2 {
      margin: 40px 0 0 0;
    }
  }
`;

const StyledScreenshot = styled.div`
  flex: 1;
  margin-top: 50px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding-left: 24px;
    width: 100%;
    margin-top: 20px;
  }
`;

const StyledImageWrap = styled.div`
  padding: 1rem 0 0 1rem;
  background-color: var(--background-blue-dark);
  align-self: flex-end;
  border-radius: 25px 0 0 0;
`;

const StyledImage = styled.div`
  height: 27rem;
  position: relative;
  margin-bottom: -2px;
  margin-right: -2px;

  img {
    border-radius: 20px 0 0 0;
    height: 100%;
  }

  span {
    display: block !important;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    height: 15rem;
  }
`;

const StyledTags = styled.div`
  position: absolute;
  top: 50px;
  left: 50px;
  display: flex;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    top: 20px;
    left: 10px;
  }
`;

const StyledTag = styled.h3`
  background-color: var(--background-blue);
  font-size: 13px;
  line-height: 100%;
  padding: 11px 18px 11px;
  margin: 0 0 0 10px;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.02rem;
`;

const WorkCard = ({ work }: TWork) => {
  const { id, title, description, url, design, development } = work;
  return (
    <StyledCard>
      <StyledTags>
        {design && <StyledTag>Design</StyledTag>}
        {development && <StyledTag>Development</StyledTag>}
      </StyledTags>
      <StyledText>
        <h2>{title}</h2>
        <p>{description}</p>
        <Button type="primary" href={url} newWindow>
          <>
            <span>View site</span>
            <Image
              src="/images/new-window.svg"
              alt="Open in new window"
              width={13}
              height={12}
            />
          </>
        </Button>
      </StyledText>
      <StyledScreenshot>
        <StyledImageWrap>
          <StyledImage>
            <Image
              src={`/images/screenshots/${id}.png`}
              alt=""
              layout="fill"
              objectFit="cover"
              objectPosition="left"
              quality={90}
            />
          </StyledImage>
        </StyledImageWrap>
      </StyledScreenshot>
    </StyledCard>
  );
};

export default WorkCard;
