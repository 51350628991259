export const AccessibilityMetrics = [
  { title: 'Aria attributes match their roles', metric: 'Pass' },
  { title: 'Html element has a lang attribute', metric: 'Pass' },
  { title: 'Aria attributes have valid values', metric: 'Pass' },
  { title: 'Document has a title element', metric: 'Pass' },
];

export const PerformanceMetrics = [
  { title: 'First Contentful Paint', metric: '0.8 s' },
  { title: 'Time to Interactive', metric: '3.2 s' },
  { title: 'Speed Index', metric: '2.5 s' },
  { title: 'Total Blocking Time', metric: '70 ms' },
];

export const SEOMetrics = [
  { title: 'robots.txt is valid', metric: 'Pass' },
  { title: 'Page isn’t blocked from indexing', metric: 'Pass' },
  { title: 'Document avoids plugins', metric: 'Pass' },
  { title: 'Images have alt attributes', metric: 'Pass' },
];
