export const CaseStudies = [
  {
    id: 'eon-next',
    title: 'EON. Next',
    description:
      'Migration of eonnext.com from legacy Gatsby website to Next.js with app router and Contentful integration',
    url: 'https://eonnext.com',
    design: false,
    development: true,
  },
  {
    id: 'zoe-nutrition-v2',
    title: 'ZOE Nutrition',
    description:
      'Development for the ZOE website in both Next.js and Webflow with DatoCMS as the headless CMS',
    url: 'https://joinzoe.com',
    design: false,
    development: true,
  },
  {
    id: 'zoe-health',
    title: 'ZOE Health Studies',
    description:
      'Development for the ZOE health study website in both Next.js and Webflow with DatoCMS as the headless CMS',
    url: 'https://health-study.joinzoe.com/',
    design: false,
    development: true,
  },
  {
    id: 'sister-co',
    title: 'Sister & Co.',
    description:
      'Shopfiy 2.0 development and design for luxury skincare and homeware brand Sister & Co',
    url: 'https://sisterandcompany.co.uk/',
    design: true,
    development: true,
  },
  {
    id: 'freerider',
    title: 'Freerider co.',
    description:
      'Design and development for luxury baby sling company Freerider co. Built on Shopify 2.0',
    url: 'https://freeriderco.com',
    design: true,
    development: true,
  },
  {
    id: 'laffinage',
    title: "L'Affinage du fromage",
    description:
      "Full design and bespoke Wordpress theme for online cheesemongers L'affinage",
    url: 'https://laffinage.co.uk',
    design: true,
    development: true,
  },
  {
    id: 'mpc-film',
    title: 'MPC Film',
    description:
      'Bespoke Wordpress theme built for Technicolor using the Roots framework',
    url: 'https://www.mpcfilm.com/',
    design: false,
    development: true,
  },
  {
    id: 'mikros-animation',
    title: 'Mikros animation',
    description:
      'Bespoke Wordpress theme built for Technicolor using the Roots framework',
    url: 'https://www.mikrosanimation.com/en/',
    design: false,
    development: true,
  },
  {
    id: 'hub8',
    title: 'Hub8',
    description:
      'Wordpress theme with GSAP and animation effects for Hub8, the cyber-tech networking hub in Cheltenham',
    url: 'https://hub8and.co/',
    design: false,
    development: true,
  },
];
