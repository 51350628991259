import Image from 'next/image';
import { useRef } from 'react';
import styled from 'styled-components';

import { Container } from '@/components/Shared';
import useIntersection from '@/hooks/useIntersection';
import { BREAKPOINTS } from '@/theme/breakpoints';

const StyledClients = styled.div`
  position: relative;
  padding: 120px 0;
  text-align: center;
  overflow: hidden;

  h3 {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 60px 0;
  }
`;

const StyledGrid = styled.div`
  display: flex;
  width: calc(280px * 24);
  animation: scroll 60s linear infinite;
  margin-top: 70px;

  div {
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 100%;
    display: block;
    height: auto;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-280px * 12));
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-top: 40px;
    width: calc(170px * 24);

    div {
      padding: 0 15px;
    }

    div:nth-child(9) {
      display: none;
    }

    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-170px * 10));
      }
    }
  }
`;

export const Clients = () => {
  const headingRef = useRef(null);
  const gridRef = useRef(null);

  const intersectionOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  };

  const headingIntersection = useIntersection(headingRef, intersectionOptions);

  return (
    <StyledClients>
      <Container size="narrow">
        <h3
          ref={headingRef}
          className={`fadeInUp${
            headingIntersection && headingIntersection.intersectionRatio < 1
              ? ''
              : ' loaded'
          }`}
        >
          Here are just some of the ambitious brands and agencies I've worked
          with:
        </h3>
      </Container>
      <StyledGrid
        ref={gridRef}
        className={`fadeInUp${
          headingIntersection && headingIntersection.intersectionRatio < 1
            ? ''
            : ' loaded'
        }`}
      >
        <div>
          <Image
            src="/images/clients/eon-next.svg"
            width={170}
            height={45}
            alt="EON. Next"
          />
        </div>
        <div>
          <Image
            src="/images/clients/zoe.svg"
            width={170}
            height={45}
            alt="Zoe"
          />
        </div>
        <div>
          <Image
            src="/images/clients/talamo.svg"
            width={170}
            height={45}
            alt="Talamo"
          />
        </div>
        <div>
          <Image
            src="/images/clients/cronofy.svg"
            width={170}
            height={45}
            alt="Cronofy"
          />
        </div>
        <div>
          <Image
            src="/images/clients/amazon.svg"
            width={170}
            height={45}
            alt="Amazon"
          />
        </div>
        <div>
          <Image
            src="/images/clients/ovo.svg"
            width={170}
            height={45}
            alt="OVO Energy"
          />
        </div>
        <div>
          <Image
            src="/images/clients/hugo-and-cat.svg"
            width={170}
            height={45}
            alt="Hugo &amp; Cat"
          />
        </div>
        <div>
          <Image
            src="/images/clients/embark.svg"
            width={170}
            height={45}
            alt="Embark Pensions"
          />
        </div>
        <div>
          <Image
            src="/images/clients/freerider.svg"
            width={170}
            height={45}
            alt="Freerider"
          />
        </div>
        <div>
          <Image
            src="/images/clients/juice-london.svg"
            width={170}
            height={45}
            alt="Juice London"
          />
        </div>
        <div>
          <Image
            src="/images/clients/kaluza.svg"
            width={170}
            height={45}
            alt="Kaluza"
          />
        </div>
        <div>
          <Image
            src="/images/clients/last-pass.svg"
            width={170}
            height={45}
            alt="LastPass"
          />
        </div>
        <div>
          <Image
            src="/images/clients/stagecoach.svg"
            width={170}
            height={45}
            alt="Stagecoach"
          />
        </div>
        <div>
          <Image
            src="/images/clients/go-city.svg"
            width={115}
            height={45}
            alt="GoCity"
          />
        </div>
        <div>
          <Image
            src="/images/clients/eon-next.svg"
            width={170}
            height={45}
            alt="EON. Next"
          />
        </div>
        <div>
          <Image
            src="/images/clients/zoe.svg"
            width={170}
            height={45}
            alt="Zoe"
          />
        </div>
        <div>
          <Image
            src="/images/clients/talamo.svg"
            width={170}
            height={45}
            alt="Talamo"
          />
        </div>
        <div>
          <Image
            src="/images/clients/cronofy.svg"
            width={170}
            height={45}
            alt="Cronofy"
          />
        </div>
        <div>
          <Image
            src="/images/clients/amazon.svg"
            width={170}
            height={45}
            alt="Amazon"
          />
        </div>
        <div>
          <Image
            src="/images/clients/ovo.svg"
            width={170}
            height={45}
            alt="OVO Energy"
          />
        </div>
        <div>
          <Image
            src="/images/clients/hugo-and-cat.svg"
            width={170}
            height={45}
            alt="Hugo &amp; Cat"
          />
        </div>
        <div>
          <Image
            src="/images/clients/embark.svg"
            width={170}
            height={45}
            alt="Embark Pensions"
          />
        </div>
        <div>
          <Image
            src="/images/clients/freerider.svg"
            width={170}
            height={45}
            alt="Freerider"
          />
        </div>
        <div>
          <Image
            src="/images/clients/juice-london.svg"
            width={170}
            height={45}
            alt="Juice London"
          />
        </div>
        <div>
          <Image
            src="/images/clients/kaluza.svg"
            width={170}
            height={45}
            alt="Kaluza"
          />
        </div>
        <div>
          <Image
            src="/images/clients/last-pass.svg"
            width={170}
            height={45}
            alt="LastPass"
          />
        </div>
        <div>
          <Image
            src="/images/clients/stagecoach.svg"
            width={170}
            height={45}
            alt="Stagecoach"
          />
        </div>
        <div>
          <Image
            src="/images/clients/go-city.svg"
            width={115}
            height={45}
            alt="GoCity"
          />
        </div>
      </StyledGrid>
    </StyledClients>
  );
};
