/* eslint-disable func-names */
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Image from 'next/image';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledCarouselWrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -2vw;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: rotate(8deg);
`;

const StyledColumnWrap = styled.div`
  position: relative;
  width: 20vw;
`;

const StyledColumn = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;

  animation: marquee-vertical 140s linear infinite;

  &.alt {
    animation: marquee-vertical-alt 140s linear infinite;
  }

  @keyframes marquee-vertical {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50%);
    }
  }

  @keyframes marquee-vertical-alt {
    from {
      transform: translateY(-50%);
    }
    to {
      transform: translateY(0%);
    }
  }
`;

const StyledCard = styled.div`
  will-change: transform;
  height: 0;
  overflow: hidden;
  width: 20vw;
  padding-top: 228%;
  position: relative;
`;

const StyledFlexVert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Carousel = () => {
  gsap.registerPlugin(ScrollTrigger);
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;

    gsap.to(carousel, {
      scrollTrigger: {
        end: 'bottom top',
        scrub: true,
        start: 'top top',
      },
      rotate: 0,
      scale: 1.2,
    });
  }, [carouselRef]);

  return (
    <StyledCarouselWrap className="carousel-wrap" ref={carouselRef}>
      <StyledColumnWrap>
        <StyledColumn>
          <StyledFlexVert>
            <StyledCard>
              <Image
                src="/images/work/eon.png"
                layout="fill"
                sizes="20vw"
                alt="EON Next"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/zoe-nutrition.png"
                layout="fill"
                sizes="20vw"
                alt="ZOE - Nutrition"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/zoe-covid-symptom-study.png"
                layout="fill"
                sizes="20vw"
                alt="ZOE - COVID Symptom Study"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/hub8.png"
                layout="fill"
                sizes="20vw"
                alt="Hub8"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/eon.png"
                layout="fill"
                sizes="20vw"
                alt="EON Next"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/zoe-nutrition.png"
                layout="fill"
                sizes="20vw"
                alt="ZOE - Nutrition"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/zoe-covid-symptom-study.png"
                layout="fill"
                sizes="20vw"
                alt="ZOE - COVID Symptom Study"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/hub8.png"
                layout="fill"
                sizes="20vw"
                alt="Hub8"
                quality={100}
                priority
              />
            </StyledCard>
          </StyledFlexVert>
        </StyledColumn>
      </StyledColumnWrap>
      <StyledColumnWrap>
        <StyledColumn className="alt">
          <StyledFlexVert>
            <StyledCard>
              <Image
                src="/images/work/cronofy.png"
                layout="fill"
                sizes="20vw"
                alt="Cronofy"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/rita.png"
                layout="fill"
                sizes="20vw"
                alt="Rita - Take charge of your reproductive health"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/sister-co.png"
                layout="fill"
                sizes="20vw"
                alt="Sister &amp; Co - Everyday luxuries that don't cost the earth"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/regen.png"
                layout="fill"
                sizes="20vw"
                alt="Regen - Cleaning up the Cayman Islands"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/cronofy.png"
                layout="fill"
                sizes="20vw"
                alt="Cronofy"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/rita.png"
                layout="fill"
                sizes="20vw"
                alt="Rita - Take charge of your reproductive health"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/sister-co.png"
                layout="fill"
                sizes="20vw"
                alt="Sister &amp; Co - Everyday luxuries that don't cost the earth"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/regen.png"
                layout="fill"
                sizes="20vw"
                alt="Regen - Cleaning up the Cayman Islands"
                quality={100}
                priority
              />
            </StyledCard>
          </StyledFlexVert>
        </StyledColumn>
      </StyledColumnWrap>
      <StyledColumnWrap>
        <StyledColumn className="last">
          <StyledFlexVert>
            <StyledCard>
              <Image
                src="/images/work/freerider.png"
                layout="fill"
                sizes="20vw"
                alt="Award-winning baby carriers"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/laffinage.png"
                layout="fill"
                sizes="20vw"
                alt="L'Affinage - Cheese, wine charcuterie &amp; more"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/mikros-animation.png"
                layout="fill"
                sizes="20vw"
                alt="Mikros Animation"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/freerider.png"
                layout="fill"
                sizes="20vw"
                alt="Award-winning baby carriers"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/laffinage.png"
                layout="fill"
                sizes="20vw"
                alt="L'Affinage - Cheese, wine charcuterie &amp; more"
                quality={100}
                priority
              />
            </StyledCard>
            <StyledCard>
              <Image
                src="/images/work/mikros-animation.png"
                layout="fill"
                sizes="20vw"
                alt="Mikros Animation"
                quality={100}
                priority
              />
            </StyledCard>
          </StyledFlexVert>
        </StyledColumn>
      </StyledColumnWrap>
    </StyledCarouselWrap>
  );
};
