import { gsap } from 'gsap/dist/gsap';
import { useEffect, useRef } from 'react';
import SplitType from 'split-type';
import styled from 'styled-components';

import { Button } from '@/components/Shared';
import { BREAKPOINTS } from '@/theme/breakpoints';

type TText = {
  loading: boolean;
};

const StyledText = styled.div`
  max-width: 450px;
  position: relative;
  z-index: 10;

  p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  a {
    margin-right: 15px;
  }

  .line {
    overflow: hidden;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin: 90px 0 60px;
    max-width: 100%;

    p {
      font-size: 15px;
      margin-bottom: 30px;
    }
  }
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-start;

  a {
    margin: 0;
  }
`;

export const Text = ({ loading }: TText) => {
  const headlineRef = useRef(null);
  const paraRef = useRef(null);
  const buttonsRef = useRef(null);

  useEffect(() => {
    if (
      headlineRef.current &&
      paraRef.current &&
      buttonsRef.current &&
      !loading
    ) {
      const text = new SplitType(headlineRef.current);
      gsap.from(text.chars, {
        yPercent: 10,
        opacity: 0,
        stagger: {
          from: 'start',
          each: 0.05,
        },
        duration: 0.5,
        delay: 0.2,
        ease: 'expo.out',
      });

      gsap.from(paraRef.current, {
        opacity: 0,
        duration: 3,
        delay: 1.8,
        ease: 'expo.out',
      });

      gsap.from(buttonsRef.current, {
        opacity: 0,
        duration: 3,
        delay: 2.2,
        ease: 'expo.out',
      });
    }
  }, [headlineRef, loading, paraRef, buttonsRef]);

  return (
    <StyledText>
      <h1 ref={headlineRef}>
        Hannah King, Award-Winning
        <br />
        Creative Developer
      </h1>
      <p ref={paraRef}>
        Are you looking for an experienced, freelance frontend developer with a
        focus on clean, performant and accessible code? I use the latest
        technologies to build cutting edge websites and applications.
      </p>
      <StyledButtons ref={buttonsRef}>
        <Button
          type="secondary"
          nextLink={false}
          href="mailto:hello@hannahking.com"
        >
          Get in touch
        </Button>
      </StyledButtons>
    </StyledText>
  );
};
