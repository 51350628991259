import splitbee from '@splitbee/web';

import { PAGESPEED_INSIGHTS_URL } from '../constants';

export const getLightHouseScore = async (
  websiteURL,
  setLoading,
  setPerformanceScore,
  setPerformanceMetrics,
  websiteURLisValid,
  setFormSubmitted
) => {
  const strategy = websiteURL.includes('hannahking') ? 'desktop' : 'mobile';
  setFormSubmitted(true);
  if (websiteURLisValid) {
    setLoading('loading');
    const lightHouseRaw = await fetch(
      `${PAGESPEED_INSIGHTS_URL}?url=${websiteURL}&key=${process.env.NEXT_PUBLIC_LIGHTHOUSE_API_KEY}&strategy=${strategy}`
    ).then((res) => res.json());
    setLoading('loaded');
    setPerformanceScore(
      Math.ceil(
        lightHouseRaw.lighthouseResult.categories.performance.score * 100
      )
    );
    const lightHouseArr = [
      {
        title: 'First Contentful Paint',
        metric:
          lightHouseRaw.lighthouseResult.audits['first-contentful-paint']
            .displayValue,
      },
      {
        title: 'Time to Interactive',
        metric: lightHouseRaw.lighthouseResult.audits.interactive.displayValue,
      },
      {
        title: 'Speed Index',
        metric:
          lightHouseRaw.lighthouseResult.audits['speed-index'].displayValue,
      },
      {
        title: 'Total Blocking Time',
        metric:
          lightHouseRaw.lighthouseResult.audits['total-blocking-time']
            .displayValue,
      },
    ];
    setPerformanceMetrics(lightHouseArr);

    // Track event
    splitbee.track('Performance check', {
      url: websiteURL,
      score: lightHouseRaw.lighthouseResult.categories.performance.score * 100,
    });
  }
};
