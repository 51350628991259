/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';
import Lottie from 'lottie-react-web';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { BREAKPOINTS } from '@/theme/breakpoints';

import animation from './loader.json';

type Props = {
  title: string;
  showStats?: boolean;
  rating: number;
  position: string;
  metrics?: {
    title: string;
    metric: string;
    metricNumber?: number;
  }[];
  loading: string;
  websiteURL?: string;
};

const PerformanceColour = (score: number): string => {
  let colour = '#70DAC0';
  if (score < 80 && score >= 60) {
    colour = '#FFA400';
  } else if (score < 60) {
    colour = '#F33333';
  }
  return colour;
};

const StyledCard = styled.div<{
  position: string;
  hide: boolean;
}>`
  background: #ffffff;
  box-shadow: 161px 225px 110px rgba(0, 0, 0, 0.01),
    90px 126px 93px rgba(0, 0, 0, 0.05), 40px 56px 69px rgba(0, 0, 0, 0.09),
    10px 14px 38px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  width: 400px;
  position: relative;
  height: 600px;
  padding: 45px 24px;
  text-align: center;

  h3 {
    font-size: 36px;
    line-height: 43px;
    font-weight: 500;
  }

  ${(p) =>
    p.position === 'left' &&
    `z-index: 5; transform: translateX(35%); opacity: 0.8;`}
  ${(p) => p.position === 'center' && `z-index: 10; translateX(50%);`}
  ${(p) =>
    p.position === 'right' &&
    `z-index: 5; transform: translateX(-35%); opacity: 0.8;`}

  ${(p) => p.hide && `transition: all 0.3s ease-in; opacity: 0;`}

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    min-width: 270px;
    padding: 25px 16px;
    height: 430px;

    h3 {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;

const StyledMetrics = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 28px 28px;
  margin-top: 50px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-top: 30px;
    gap: 20px 10px;
  }
`;

const StyledMetric = styled.div<{ colour: string }>`
  text-align: left;
  position: relative;
  padding-left: 22px;

  div.title {
    font-size: 14px;
    color: var(--text-grey);
  }

  div.metric {
    font-size: 28px;
    line-height: 33px;
    margin-top: 6px;
  }

  &::before {
    content: '';
    width: 14px;
    height: 14px;
    background-color: ${(p) => p.colour};
    border-radius: 7px;
    position: absolute;
    top: 4px;
    left: 0;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding-left: 12px;

    div.title {
      font-size: 9px;
    }

    div.metric {
      font-size: 20px;
      line-height: 28px;
      margin-top: 5px;
    }

    &::before {
      width: 8px;
      height: 8px;
    }
  }
`;

const StyledProgress = styled.div`
  margin-bottom: 25px;
  > div {
    margin: 0 auto;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    > div {
      max-width: 150px;
      max-height: 150px;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  tspan {
    font-weight: 500;
  }
`;

const StyledLoaderAnimation = styled.div`
  max-width: 100px;
  height: auto;
  margin: 40px auto;
  font-size: 1.5rem;
`;

const StyledLoader = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3,
  h4 {
    margin-bottom: 0;
  }

  p {
    font-size: 1.2rem;
  }

  .animation {
    vertical-align: sub;
    height: 1em;
    overflow: hidden;
    display: inline-block;
    line-height: 2em;
  }

  ul {
    animation: animation 12s infinite;
    padding: 0;
    margin-top: -0.55em;
    list-style: none;
    text-align: left;
  }

  @keyframes animation {
    0% {
      margin-top: -0.55em;
    }
    12.5% {
      margin-top: -2.54em;
    }
    25% {
      margin-top: -4.55em;
    }
    37.5% {
      margin-top: -6.55em;
    }
    50% {
      margin-top: -8.5em;
    }
    62.5% {
      margin-top: -6.55em;
    }
    75% {
      margin-top: -4.55em;
    }
    87.5% {
      margin-top: -2.54em;
    }
    100% {
      margin-top: -0.55em;
    }
  }
`;

const PerformanceCard = (
  {
    title,
    showStats = false,
    rating,
    metrics,
    position,
    loading,
    websiteURL,
  }: Props,
  ref: any
) => {
  const chartColour = PerformanceColour(rating);

  if (loading === 'loading' && position === 'center') {
    return (
      <StyledCard ref={ref} position={position} hide={position !== 'center'}>
        <StyledLoader>
          <h3>Analysing</h3>
          <h4>{`${websiteURL}`}</h4>
          <StyledLoaderAnimation>
            <Lottie options={{ animationData: animation }} />
          </StyledLoaderAnimation>
          <p>
            {`Checking `}{' '}
            <div className="animation">
              <ul>
                <li>performance</li>
                <li>seo</li>
                <li>accessibility</li>
                <li>layout shift</li>
                <li>carbon score</li>
              </ul>
            </div>
          </p>
        </StyledLoader>
      </StyledCard>
    );
  }
  return (
    <StyledCard
      ref={ref}
      position={position}
      hide={
        (loading === 'loaded' || loading === 'loading') && position !== 'center'
      }
    >
      <StyledProgress>
        <CircularProgressBar
          percent={rating}
          round
          colorCircle="#f1f1f1"
          colorSlice={chartColour}
          unit=""
        />
      </StyledProgress>
      <h3>{title}</h3>
      <StyledMetrics>
        {showStats &&
          metrics?.map((metric) => {
            return (
              <StyledMetric key={uuid()} colour={chartColour}>
                <div className="title">{metric.title}</div>
                <div className="metric">{metric.metric}</div>
              </StyledMetric>
            );
          })}
      </StyledMetrics>
    </StyledCard>
  );
};
export default forwardRef(PerformanceCard);
