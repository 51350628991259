import { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import styled from 'styled-components';

import { Container } from '@/components/Shared';
import useIntersection from '@/hooks/useIntersection';
import { BREAKPOINTS } from '@/theme/breakpoints';

import { Quotes } from './Quotes';

const StyledTestimonials = styled.div`
  position: relative;
  padding: 80px 0;
  overflow-x: hidden;
  text-align: center;
  color: white;
  background-color: var(--background-blue-dark);

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 60px 0;
  }
`;

export const StyledPreTitle = styled.h3`
  font-size: 1.13rem;
  margin-bottom: 0;
  font-weight: 400;
`;

export const StyledIntro = styled.p`
  font-size: 1.15rem;
  font-weight: 400;
  max-width: 700px;
  margin: 0 auto 0;
`;

const StyledCard = styled.div`
  border-radius: 8px;
  background-color: var(--background-blue-dark-contrast);
  margin: 0;
  text-align: left;
  font-weight: 500;
  padding: 24px;
  box-shadow: 0 4px 16px -8px rgb(0 0 0 / 8%);
  margin: 8px;

  p {
    font-size: 15px;
    line-height: 26px;
  }
`;

const StyledButton = styled.button`
  display: inline-block;
  margin: 0 auto;
  padding: 16px 40px;
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  border: none;
  will-change: background-color, color, box-shadow;
  transition: background-color 0.3s ease-in, color 0.3s ease-in,
    box-shadow 0.3s ease-in;
  background-color: white;
  color: var(--background-blue-dark);
  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 14px 26px;
  }
`;

const StyledGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  background: linear-gradient(
    0deg,
    rgba(21, 20, 26, 1) 50%,
    rgba(21, 20, 26, 0) 100%
  );
`;

const StyledHead = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  figcaption {
    flex: 1;
    line-height: 20px;

    span {
      display: block;
    }

    cite {
      font-size: 14px;
      font-style: normal;
      color: #818195;
    }
  }
`;

const StyledIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 15px;
  background-color: var(--background-blue-dark);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: auto;
    path {
      fill: var(--green);
    }
  }
`;

export const Testimonials = ({ setTheme }) => {
  const [showAll, setShowAll] = useState(false);
  const intersectionOptions = {
    threshold: 0.15,
  };
  const testimonialRef = useRef(null);
  const testimonialIntersection = useIntersection(
    testimonialRef,
    intersectionOptions
  );

  useEffect(() => {
    if (testimonialIntersection?.isIntersecting) {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }, [testimonialIntersection?.isIntersecting]);

  const expandTestimonialsClickHandler = () => {
    setShowAll((val) => !val);
  };

  return (
    <StyledTestimonials ref={testimonialRef}>
      <Container size="narrow">
        <StyledPreTitle>Client testimonials</StyledPreTitle>
        <h2>Hear from the people who matter</h2>
        <AnimateHeight height={showAll ? 'auto' : 480} duration={300}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry>
              {Quotes.map((quote) => {
                return (
                  <StyledCard key={quote.id}>
                    <blockquote>
                      <StyledHead>
                        <StyledIcon>
                          <svg
                            viewBox="0 0 24 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_317_6290)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.3988 10.4C0.1788 10.4 0 10.2196 0 9.9988V0.4012C0 0.18 0.18 0 0.4008 0H9.9888C10.21 0 10.39 0.1804 10.39 0.4012L10.4 10.4C10.1816 14.4116 6.8632 17.6 2.8 17.6H2.7988C2.5788 17.6 2.4 17.4176 2.4 17.202V13.998C2.40053 13.8925 2.44274 13.7914 2.51744 13.7169C2.59213 13.6423 2.69327 13.6003 2.7988 13.6H2.8C4.6532 13.6 6.1792 12.2 6.378 10.4H0.3988ZM13.9988 10.4C13.7788 10.4 13.6 10.2196 13.6 9.9988V0.4012C13.6 0.18 13.78 0 14.0008 0H23.5888C23.81 0 23.99 0.1804 23.99 0.4012L24 10.4C23.7816 14.4116 20.4632 17.6 16.4 17.6H16.3988C16.1788 17.6 16 17.4176 16 17.202V13.998C16.0005 13.8925 16.0427 13.7914 16.1174 13.7169C16.1921 13.6423 16.2933 13.6003 16.3988 13.6H16.4C18.2532 13.6 19.7792 12.2 19.978 10.4H13.9988V10.4Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_317_6290">
                                <rect width="24" height="17.6" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </StyledIcon>
                        <figcaption>
                          <span>{quote.name}</span>
                          <cite>{quote.company}</cite>
                        </figcaption>
                      </StyledHead>
                      <p>{quote.quote}</p>
                    </blockquote>
                  </StyledCard>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
          {!showAll && <StyledGradient />}
        </AnimateHeight>
        <StyledButton
          type="submit"
          className="secondary"
          onClick={expandTestimonialsClickHandler}
        >
          {`${showAll ? 'Show less' : 'Show more'}`}
        </StyledButton>
      </Container>
    </StyledTestimonials>
  );
};
