import 'swiper/swiper.min.css';

import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container } from '@/components/Shared';
import useIntersection from '@/hooks/useIntersection';
import { BREAKPOINTS } from '@/theme/breakpoints';

import ServicesCard from './ServicesCard';

const StyledServices = styled.div`
  position: relative;
  padding: 80px 0 40px;
  overflow: hidden;
  text-align: center;
  color: white;
  background-color: var(--background-blue-dark);

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 60px 0;
  }

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    transition: transform 0.4s ease-in;
    width: auto;
  }
`;

const StyledPreTitle = styled.h3`
  font-size: 1.13rem;
  margin-bottom: 0;
  font-weight: 400;
`;

export const Services = ({ setTheme }) => {
  const intersectionOptions = {
    threshold: 0.35,
  };
  const servicesRef = useRef(null);
  const servicesIntersection = useIntersection(
    servicesRef,
    intersectionOptions
  );

  useEffect(() => {
    if (servicesIntersection?.isIntersecting) {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }, [servicesIntersection?.isIntersecting]);

  return (
    <StyledServices ref={servicesRef}>
      <div
        className={`fadeInUp ${
          servicesIntersection?.isIntersecting ? 'loaded' : ''
        }`}
      >
        <Container size="narrow">
          <StyledPreTitle>Expertise</StyledPreTitle>
          <h2>Want to know what I specialise in?</h2>
        </Container>
      </div>
      <div
        className={`fadeInUp ${
          servicesIntersection?.isIntersecting ? 'loaded' : ''
        }`}
      >
        <Container size="narrow">
          <Swiper
            speed={1000}
            freeMode
            slidesPerView={1.1}
            spaceBetween={20}
            breakpoints={{
              900: {
                slidesPerView: 3,
              },
              1440: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <ServicesCard
                service="Next.js"
                description="Developing using the latest technologies from Next.js on the frontend to headless CMS powering the backend"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ServicesCard
                service="Sanity CMS"
                description="The most performant headless CMS that pairs perfectly with Next.js for unrivalled content editing experience."
              />
            </SwiperSlide>
            <SwiperSlide>
              <ServicesCard
                service="Wordpress"
                description="From bespoke themes & plugins to using it as a headless CMS, I like to push the boundaries of what's possible with Wordpress."
              />
            </SwiperSlide>
            <SwiperSlide>
              <ServicesCard
                service="Shopify"
                description="I am able to work with all aspects of Shopify, from headless eCommerce to fully bespoke shopify themes to hydrogen, Shopify's own headless framework"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ServicesCard
                service="App development"
                description="From React Native to PWA I am able to design and develop a variety of mobile apps."
              />
            </SwiperSlide>
            <SwiperSlide>
              <ServicesCard
                service="Design"
                description="With a background in design and marketing I am able to provide a wide range of digital design services."
              />
            </SwiperSlide>
            <SwiperSlide>
              <ServicesCard
                service="Digital sustainability"
                description="Passionate about reducing the impact that your websites add applications have on the planet by optimising performance."
              />
            </SwiperSlide>
          </Swiper>
        </Container>
      </div>
    </StyledServices>
  );
};
