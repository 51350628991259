import type { NextPage } from 'next';
import { useEffect, useState } from 'react';

import { Footer, Head } from '@/components';
import {
  Badge,
  Clients,
  Hero,
  Loader,
  Performance,
  Services,
  Testimonials,
  Work,
} from '@/components/Home';

const HomePage: NextPage = () => {
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    setTimeout(() => setLoading(false), 2900);
  }, []);

  return (
    <>
      <Head />
      <Hero loading={loading} />
      <Clients />
      <Work />
      <Services setTheme={setTheme} />
      <Badge loading={loading} theme={theme} />
      <Loader loading={loading} />
      <Performance />
      <Testimonials setTheme={setTheme} />
      <Footer />
    </>
  );
};

export default HomePage;
