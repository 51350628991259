import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';

type TServices = {
  service: string;
  description: string;
};
const StyledCard = styled.div`
  display: block;
  border-radius: 10px;
  margin: 20px 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  cursor: grab;
  margin-top: 40px;
  background-color: var(--background-blue-dark-contrast);
  height: 450px;
  transition: all 0.4s ease-in;
  position: relative;

  h1 {
    white-space: nowrap;
    font-size: 15rem;
    line-height: 100%;
    background-color: inherit;
    background-repeat: repeat-x;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: 2px var(--text-grey);
    color: transparent;
    padding-left: 8.3rem;
    letter-spacing: -0.1rem;
    opacity: 0.2;
    font-weight: 700;
    transform: translateX(0);
    transition: 1s;
  }

  &:hover {
    h1 {
      transform: translateX(calc(200px - 100%));
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-top: 20px;
  }
`;

const StyledHero = styled.div`
  overflow: hidden;
`;

const StyledText = styled.div`
  padding: 0 28px 28px;

  h3 {
    font-weight: 600;
    margin-bottom: 0.4rem;
    font-size: 1.7rem;
  }

  p {
    color: var(--text-grey);
  }
`;

const ServicesCard = ({ service, description }: TServices) => {
  return (
    <StyledCard>
      <StyledHero>
        <h1>{service}</h1>
      </StyledHero>
      <StyledText>
        <h3>{service}</h3>
        <p>{description}</p>
      </StyledText>
    </StyledCard>
  );
};

export default ServicesCard;
