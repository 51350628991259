import 'swiper/swiper.min.css';

import { useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import useIntersection from '@/hooks/useIntersection';
import { BREAKPOINTS } from '@/theme/breakpoints';

import { CaseStudies } from './CaseStudies';
import WorkCard from './WorkCard';

const StyledWork = styled.div`
  position: relative;
  padding: 80px 0 40px;
  overflow-x: hidden;
  text-align: center;
  background-color: var(--background-blue);

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 60px 0;
  }

  .swiper-slide {
    transition: transform 0.4s ease-in;

    &.swiper-slide-prev {
      transform: scale(0.85);
    }

    &.swiper-slide-next {
      transform: scale(0.85);
    }
  }
`;

const StyledPreTitle = styled.h3`
  font-size: 1.13rem;
  margin-bottom: 0;
  font-weight: 400;
`;

export const Work = () => {
  const intersectionOptions = {
    threshold: 0.35,
  };
  const workRef = useRef(null);
  const workIntersection = useIntersection(workRef, intersectionOptions);

  return (
    <StyledWork ref={workRef}>
      <div
        className={`fadeInUp ${
          workIntersection?.isIntersecting ? 'loaded' : ''
        }`}
      >
        <StyledPreTitle>Case studies</StyledPreTitle>
        <h2>Let's get to the good stuff</h2>
      </div>
      <div
        className={`fadeInUp ${
          workIntersection?.isIntersecting ? 'loaded' : ''
        }`}
      >
        <Swiper
          slidesPerView={1.3}
          centeredSlides
          loop
          speed={800}
          freeMode
          breakpoints={{
            900: {
              slidesPerView: 1.6,
            },
            1440: {
              slidesPerView: 2,
            },
          }}
        >
          {CaseStudies.map((caseStudy) => (
            <SwiperSlide key={caseStudy.id}>
              <WorkCard work={caseStudy} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </StyledWork>
  );
};
