import Image from 'next/image';
import Link from 'next/link';
import { useRef } from 'react';
import Div100vh from 'react-div-100vh';
import styled from 'styled-components';

import { Container } from '@/components/Shared';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { BREAKPOINTS } from '@/theme/breakpoints';

import { Availability } from './Availability';
import { Carousel } from './Carousel';
import { CarouselMobile } from './CarouselMobile';
import { Text } from './Text';

type THero = {
  loading: boolean;
};

const StyledHero = styled(Div100vh)`
  position: relative;
  overflow: hidden;
  background-color: var(--background-blue);

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    height: auto !important;
  }
`;

const StyledAnimateIn = styled.div`
  transform: translateY(100px);
  transition: all 0.5s ease-in;
  will-change: transform, opacity;
  height: 100%;
  opacity: 0;

  &.loaded {
    transform: translateY(0);
    opacity: 1;
  }
`;

const StyledLogo = styled.div`
  position: absolute;
  top: 40px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    top: 28px;
    max-width: 24px;
    height: auto;
  }
`;

export const Hero = ({ loading }: THero) => {
  const containerRef = useRef(null);
  const { isMobile } = useDeviceDetect();

  return (
    <StyledHero ref={containerRef}>
      <StyledAnimateIn className={`${loading ? 'loading' : 'loaded'}`}>
        <Container flex align="center-desktop" height="100%">
          <StyledLogo>
            <Link href="/" passHref>
              <a>
                <Image
                  src="/images/hannah-king-logo.svg"
                  alt="Hannah King"
                  width={50}
                  height={37}
                />
              </a>
            </Link>
          </StyledLogo>
          <Text loading={loading} />
        </Container>
        {isMobile ? <CarouselMobile /> : <Carousel />}
      </StyledAnimateIn>
      {/* <Availability available={false} /> */}
    </StyledHero>
  );
};
