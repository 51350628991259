import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme/breakpoints';

const StyledLighthouse = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  font-size: 14px;

  svg {
    margin-left: 10px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    font-size: 13px;
    margin-top: 40px;
  }
`;

export const Lighthouse = () => {
  return (
    <StyledLighthouse>
      <p>Powered by Google Lighthouse</p>
      <svg width="15" height="16" viewBox="0 0 15 16" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.97384 15.5775L5.18149 13.6847L9.11714 12.1947L9.48825 15.5775C8.76122 15.8582 7.99867 16.0009 7.23105 16C6.46342 16.0009 5.70087 15.8582 4.97384 15.5775ZM3.75609 14.9527C1.63841 13.5753 0.210938 10.9776 0.210938 8C0.210938 3.58173 3.35395 0 7.23105 0C11.1081 0 14.2512 3.58173 14.2512 8C14.2512 10.9776 12.8237 13.5753 10.706 14.9527L9.94336 8H10.422V6.54545H9.78381V3.63636L7.23105 1.81818L4.67828 3.63636V6.54545H4.04009V8H4.51873L3.75609 14.9527ZM8.65701 8L8.88692 10.0955L5.43127 11.4075L5.80509 8H8.65701ZM5.95466 6.54545V4.44254L7.23105 3.53345L8.50743 4.44254V6.54545H5.95466Z"
          fill="#030303"
        />
      </svg>
    </StyledLighthouse>
  );
};
