import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme/breakpoints';

export const StyledPerformance = styled.section`
  position: relative;
  background-color: var(--background-blue);
  text-align: center;
  padding: 120px 0;
  overflow: hidden;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 60px 0;
  }
`;

export const StyledPreTitle = styled.h3`
  font-size: 1.13rem;
  margin-bottom: 0;
  font-weight: 400;
`;

export const StyledIntro = styled.p`
  font-size: 1.15rem;
  font-weight: 400;
  max-width: 700px;
  margin: 15px auto 0;
`;

export const StyledCards = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin-top: 30px;
  }
`;

export const StyledForm = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: white;
  margin: 40px auto 0;
  padding: 10px;
  border-radius: 150px;
  display: flex;
  border: 1px solid rgba(112, 218, 192, 1);
  box-shadow: 0 0 10px rgba(112, 218, 192, 0.4);
  animation: pulse 2s infinite;

  input[type='text'] {
    flex: 1;
    border: none;
    padding: 5px 10px;
    border-radius: 50px;
    outline: none;
    background-color: white;
  }

  button {
    border-radius: 50px;
    border: none;
    padding: 8px 30px;
    font-weight: 500;
    background-color: black;
    color: white;
    cursor: pointer;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 4px 0 rgba(112, 218, 192, 0.4);
    }
    70% {
      box-shadow: 0 0 4px 20px rgba(112, 218, 192, 0);
    }
    100% {
      box-shadow: 0 0 4px 0 rgba(112, 218, 192, 0);
    }
  }
`;

export const StyledErrorMsg = styled.p`
  color: red;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
`;
